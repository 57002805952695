
function App() {
  return (
    <>
      <div className="flex flex-col flex-1 mt-40  text-gray-300">
        <div className="flex flex-col items-center justify-center min-w-80 mx-auto">
          <img className="rounded-full w-[120px] h-[120px] mb-3" alt="MB" src="https://mehmetbenlioglu.com/assets/mehmet.png"/>
          <div className="mb-1 text-[22px] text-[#26B1A1]">Mehmet BENLİOĞLU</div>
          <div className="text-lg"><span className="text-[#26B1A1]">28</span> / Türkiye</div> 
          <div className="mt-8"><span className="text-[#26B1A1]">/</span> Full-stack Developer <span className="text-[#26B1A1]">/</span></div>
          <div className="mt-1">Istanbul</div>

          <div className="mt-8 text-[#26B1A1] text-xl">Find me on</div>
          <div className="flex gap-7 mt-3 flex-wrap">
            <a href="https://www.youtube.com/@mbnl"><img src="/assets/youtube.svg" alt="youtube" className="h-[20px]" /></a>
            <a href="https://twitter.com/mbnl__"><img src="/assets/x.svg" alt="x" className="h-[20px]" /></a>
            <a href="https://www.instagram.com/mehmettt.94/"><img src="/assets/instagram.svg" alt="instagram" className="h-[20px]" /></a>
            <a href="https://www.linkedin.com/in/mehmet-b-978abb220/"><img src="/assets/linkedin.svg" alt="linkedin" className="h-[20px]" /></a>
            <a href="https://t.me/mehmetbnl"><img src="/assets/telegram.svg" alt="telegram" className="h-[20px]" /></a>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
